<template>
  <v-container class="ticket-container">
    <CategoryTitle :category="category" />

    <div class="d-flex flex-column align-center justify-center">
      <v-img
        contain
        class="d-block mb-5"
        src="/img_layout/reserved_area/ticket/ticket-sent.png"
        alt="Ticket inviato"
        max-width="250px"
        max-height="250px"
      />

      <v-btn color="primary" large depressed :to="{ name: 'Home' }">{{
        $t("tickets.sent.continueShoppingBtn")
      }}</v-btn>
    </div>
  </v-container>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

export default {
  name: "TicketSentSuccessfull",
  data() {
    return {};
  },
  mixins: [reload, categoryMixin],
  components: { CategoryTitle },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
